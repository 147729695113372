<template>
	<div>
		<seller-branch-filter
			class="mb-3"
			labelText="คำสั่งซื้อสาขา"
			@changeBranch="changeBranch($event)">
		</seller-branch-filter>

		<h2>ยืนยันการจัดส่ง</h2>

		<div class="loading" v-if="!isResultReady">
			<div class="item">
				<loader-content :isDisplay="true"></loader-content>
			</div>
		</div>

		<seller-order-card
			class="mb-3"
			@updateOrder="getOrderList()"
			:content="item" v-for="(item, index) in resultList" :key="index">
		</seller-order-card>

		<paging
			@page="changePage($event)"
			:activePage="activePage"
			:totalPage="totalPage"
			class="mt-4 mb-2">
		</paging>

		<div class="no-data" v-if="isResultReady && resultList.length === 0">
			<b-icon icon="box-seam"></b-icon>
			<span>ยังไม่มีรายการจัดส่ง</span>
		</div>
	</div>
</template>

<script>
import SellerBranchFilter from '@/components/seller/BranchFilter';
import SellerOrderCard from '@/components/seller/orders/OrderCard';
import SellerService from '@/services/sellerService';
import Constants from '@/variables/constants';
import RouterPath from '@/router/path';

export default {
	components: {
		SellerBranchFilter,
		SellerOrderCard
	},
	data() {
		return {
			isResultReady: false,
			statusList: [],
			resultList: [],
			activePage: 1,
			activeStatus: Constants.ORDER_STATUS.WAITING_DELIVERY,
			totalPage: 0
		}
	},
	mounted() {
		this.getOrderList();
	},
	methods: {
		async getOrderList() {
			this.isResultReady = false;

			const param = this.setupParam();
            const result = await SellerService.getOrderList(param);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.resultList = result.data.resultList;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
		},
		setupParam() {
			const param = {
				page: this.activePage,
				status: this.activeStatus,
				branchId: this.branchId
			};

			return param;
		},
		changePage(page) {
			this.activePage = page;
			this.getOrderList();
		},
		changeBranch(branchId) {
			this.activePage = 1;
			this.branchId = branchId;
			this.getOrderList();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 22px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 20px;
}

.loading {
	.item {
		height: 300px;
		margin-bottom: 20px;
	}
}
</style>